<template>
  <form class="form" novalidate="novalidate">
    <div class="row">
      <div class="col-md-6">
        <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
          <label class="d-flex align-items-center fw-semi-bold mb-2">
            <span class="required">Common Name</span>
          </label>
          <input
            v-model="form.english_name"
            :class="['form-control form-control-solid', validateModel($v.form.english_name.$error)]"
            type="text"
            placeholder="Enter Common Name"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
          <label class="d-flex align-items-center fw-semi-bold mb-2">
            <span class="required">Botanical Name</span>
          </label>
          <input
            v-model="form.botanical_name"
            :class="['form-control form-control-solid', validateModel($v.form.botanical_name.$error)]"
            type="text"
            placeholder="Enter Botanical Name"
          />
        </div>
      </div>
    </div>
    <div>
      <input :value="$v.form.item_size.$model" class="form-control form-control-solid" type="hidden" />
      <input :value="$v.form.price.$model" class="form-control form-control-solid" type="hidden" />

      <div v-for="(item, index) in this.$v.form.price_variables.$each.$iter" :key="item.id.$model">
        <div v-if="item.id.$model !== '0'" class="row">
          <div class="col-md">
            <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label class="d-flex align-items-center fw-semi-bold mb-2">
                <span class="required">Size</span>
              </label>
              <input
                v-model="item.$model.size"
                :class="['form-control form-control-solid', validateModel(item.size.$error)]"
                type="text"
                @input="() => ($v.form.item_size.$model = $v.form.price_variables.$model[0].size)"
              />
            </div>
          </div>
          <div class="col-md">
            <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label class="d-flex align-items-center fw-semi-bold mb-2">
                <span class="required">Cost</span>
              </label>

              <PxMoney
                v-model="item.$model.cost"
                :class="['form-control form-control-solid', validateModel(item.cost.$error)]"
                :decimal-scale="1"
                @input="onChangeCost(item)"
              />
            </div>
          </div>
          <div class="col-md">
            <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label class="d-flex align-items-center fw-semi-bold mb-2">
                <span class="required">Multiplier</span>
              </label>

              <PxMoney
                v-model="item.$model.multiplier"
                :class="['form-control form-control-solid', validateModel(item.multiplier.$error)]"
                :decimal-scale="1"
                @input="onChangeMultiplier(item)"
              />
            </div>
          </div>
          <div class="col-md">
            <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label class="d-flex align-items-center fw-semi-bold mb-2">
                <span class="required">Price</span>
              </label>

              <PxMoney
                v-model="item.$model.price"
                :class="['form-control form-control-solid', validateModel(item.price.$error)]"
                @input="onChangePrice(item)"
              />
            </div>
          </div>
          <div class="col-md-auto mb-8 mb-md-0 repeater-delete">
            <div class="w-45px">
              <span
                v-if="index > 0"
                class="btn btn-icon btn-light-danger mt-n5 mt-md-8"
                role="button"
                @click="() => deletePriceVariablesItem(item.id.$model)"
              >
                <i class="bi bi-trash" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-md-n4 mb-6 mb-md-4">
        <span class="text-primary text-nowrap" role="button" @click="addNewSize">
          <span class="me-1">+</span>
          Add Size
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="d-flex flex-column mb-8">
          <label class="fw-semi-bold mb-2 required">Category</label>
          <px-select
            v-model="form.category"
            classes="form-select form-select-solid"
            :state="$v.form.category.$error"
            :clear="false"
            placeholder="Select a Category"
            :items="possibleCategories"
            :minimum-results-for-search="Infinity"
            track-by="title"
            text-by="title"
            select2-id="select2_category"
            @input="onChangeCategory"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex flex-column mb-8">
          <label class="fw-semi-bold mb-2 required">Sub Category</label>
          <px-select
            v-model="form.subcategory"
            classes="form-select form-select-solid"
            :state="$v.form.subcategory.$error"
            :clear="false"
            placeholder="Select a Sub Category"
            :items="possibleSubCategories"
            :minimum-results-for-search="Infinity"
            track-by="title"
            text-by="title"
            select2-id="select2_sub_category"
          />
        </div>
      </div>
    </div>
    <div class="d-flex flex-column mb-8">
      <label class="fw-semi-bold mb-2 required">Description</label>
      <textarea-autosize
        v-model="form.description"
        :class="['form-control form-control-solid', validateModel($v.form.description.$error)]"
        placeholder="Type Item Description"
        rows="1"
        :min-height="42"
        :max-height="500"
      />
    </div>

    <div class="row mx-lg-n8 mb-4">
      <div class="col-md-6 col-lg-4 px-lg-8">
        <label class="d-flex align-items-center cursor-pointer mb-5">
          <span class="form-check form-check-sm form-check-custom form-check-solid me-4">
            <input v-model="form.full_sun" class="form-check-input" type="checkbox" />
          </span>
          <span class="d-flex align-items-center">
            <span class="svg-icon svg-icon-primary svg-icon-2x me-3">
              <inline-svg src="/assets/media/icons/custom/full-sun.svg" />
            </span>
            <span>Full Sun</span>
          </span>
        </label>
      </div>
      <div class="col-md-6 col-lg-4 px-lg-8">
        <label class="d-flex align-items-center cursor-pointer mb-5">
          <span class="form-check form-check-sm form-check-custom form-check-solid me-4">
            <input v-model="form.partial_sun" class="form-check-input" type="checkbox" />
          </span>
          <span class="d-flex align-items-center">
            <span class="svg-icon svg-icon-primary svg-icon-2x me-3">
              <inline-svg src="/assets/media/icons/custom/partial-sun.svg" />
            </span>
            <span>Partial Sun</span>
          </span>
        </label>
      </div>
      <div class="col-md-6 col-lg-4 px-lg-8">
        <label class="d-flex align-items-center cursor-pointer mb-5">
          <span class="form-check form-check-sm form-check-custom form-check-solid me-4">
            <input v-model="form.full_shade" class="form-check-input" type="checkbox" />
          </span>
          <span class="d-flex align-items-center">
            <span class="svg-icon svg-icon-primary svg-icon-2x me-3">
              <inline-svg src="/assets/media/icons/custom/full-shade.svg" />
            </span>
            <span>Full Shade</span>
          </span>
        </label>
      </div>
      <div class="col-md-6 col-lg-4 px-lg-8">
        <label class="d-flex align-items-center cursor-pointer mb-5">
          <span class="form-check form-check-sm form-check-custom form-check-solid me-4">
            <input v-model="form.native" class="form-check-input" type="checkbox" />
          </span>
          <span class="d-flex align-items-center">
            <span class="svg-icon svg-icon-primary svg-icon-2x me-3">
              <inline-svg src="/assets/media/icons/custom/native.svg" stroke="#00a3ff" />
            </span>
            <span>Native</span>
          </span>
        </label>
      </div>
      <div class="col-md-6 col-lg-4 px-lg-8">
        <label class="d-flex align-items-center cursor-pointer mb-5">
          <span class="form-check form-check-sm form-check-custom form-check-solid me-4">
            <input v-model="form.attracts_birds" class="form-check-input" type="checkbox" />
          </span>
          <span class="d-flex align-items-center">
            <span class="svg-icon svg-icon-primary svg-icon-2x me-3">
              <inline-svg src="/assets/media/icons/custom/attractive-birds.svg" stroke="#00a3ff" />
            </span>
            <span>Attracts Birds</span>
          </span>
        </label>
      </div>
    </div>

    <div class="row mx-lg-n8 mb-4">
      <div class="col-md-6 col-lg-4 px-lg-8">
        <div class="d-flex flex-stack align-items-center mb-4">
          <div class="input-group input-group-solid">
            <span class="input-group-text">
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <inline-svg src="/assets/media/icons/custom/height.svg" />
              </span>
            </span>
            <input
              v-model="form.height"
              type="text"
              placeholder="Type a Height"
              class="form-control form-control-solid border-0 px-0"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 px-lg-8">
        <div class="d-flex flex-stack align-items-center mb-4">
          <div class="input-group input-group-solid">
            <span class="input-group-text">
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <inline-svg src="/assets/media/icons/custom/bloom-color.svg" />
              </span>
            </span>
            <input
              v-model="form.bloom"
              type="text"
              placeholder="Type Bloom Color"
              class="form-control form-control-solid border-0 px-0"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 px-lg-8">
        <div class="d-flex flex-stack align-items-center mb-4">
          <div class="input-group input-group-solid">
            <span class="input-group-text">
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <inline-svg src="/assets/media/icons/custom/spread.svg" />
              </span>
            </span>
            <input
              v-model="form.spread"
              type="text"
              placeholder="Type a Spread"
              class="form-control form-control-solid border-0 px-0"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 px-lg-8">
        <div class="d-flex flex-stack align-items-center mb-4">
          <div class="input-group input-group-solid">
            <span class="input-group-text">
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <inline-svg src="/assets/media/icons/custom/foliage-value.svg" />
              </span>
            </span>
            <input
              v-model="form.foliage"
              type="text"
              placeholder="Type Foliage Value"
              class="form-control form-control-solid border-0 px-0"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 px-lg-8">
        <div class="d-flex flex-stack align-items-center mb-4">
          <div class="input-group input-group-solid">
            <span class="input-group-text">
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <inline-svg src="/assets/media/icons/custom/shape.svg" />
              </span>
            </span>
            <div class="flex-fill">
              <px-select
                v-model="form.shape"
                classes="form-select form-select-solid ps-0 bg-transparent border-0"
                :clear="false"
                placeholder="Select a shape"
                :items="possibleShapes"
                :minimum-results-for-search="Infinity"
                track-by="title"
                text-by="title"
                select2-id="select2_shape"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 px-lg-8">
        <div class="d-flex flex-stack align-items-center mb-4">
          <div class="input-group input-group-solid">
            <span class="input-group-text">
              <span class="svg-icon svg-icon-primary svg-icon-2x">
                <inline-svg src="/assets/media/icons/custom/zone-value.svg" stroke="#00a3ff" />
              </span>
            </span>
            <input
              v-model="form.zone"
              type="text"
              placeholder="Type Zone Value"
              class="form-control form-control-solid border-0 px-0"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
      <label class="d-flex align-items-center fw-semi-bold mb-2">Client Note</label>
      <textarea-autosize
        v-model="form.note"
        class="form-control form-control-solid bg-light-warning border-0"
        placeholder="Type Client Note"
        rows="1"
        :min-height="42"
        :max-height="500"
      />
    </div>
    <div class="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
      <label class="d-flex align-items-center fw-semi-bold mb-2">Internal Note</label>
      <textarea-autosize
        v-model="form.internal_note"
        class="form-control form-control-solid bg-light-danger border-0"
        placeholder="Type Internal Note"
        rows="1"
        :min-height="42"
        :max-height="500"
      />
    </div>

    <!--begin::Actions-->
    <div class="d-flex flex-stack">
      <px-btn color="light" extended-classes="btn-active-light-primary me-2" @click.native="close">Cancel</px-btn>
      <div class="d-flex align-items-center justify-content-end">
        <px-btn
          v-if="!templateId"
          color="light-primary"
          extended-classes="me-4"
          :in-process="createAndAddToBuilderInProcess"
          :disabled="createAndAddToBuilderInProcess || updateInProcess || createInProcess"
          @click.native="() => onSubmit(true)"
        >
          Create & Add To Builder
        </px-btn>
        <px-btn
          v-if="templateId"
          :in-process="updateInProcess"
          :disabled="updateInProcess || createAndAddToBuilderInProcess"
          @click.native="onUpdate"
        >
          Update
        </px-btn>
        <px-btn
          v-else
          :in-process="createInProcess"
          :disabled="createInProcess || createAndAddToBuilderInProcess"
          @click.native="() => onSubmit(false)"
        >
          Create
        </px-btn>
      </div>
    </div>
    <!--end::Actions-->
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { nanoid } from 'nanoid';

import PlantingItemTemplateMixin from '@/mixins/PlantingItemTemplateMixin';

export default {
  mixins: [PlantingItemTemplateMixin],
  computed: {
    ...mapGetters({
      message: 'plantingItemTemplate/message',
    }),
  },
  data() {
    return {
      createAndAddToBuilderInProcess: false,
      updateInProcess: false,
      createInProcess: false,
    };
  },
  methods: {
    ...mapActions({
      create: 'plantingItemTemplate/create',
      update: 'plantingItemTemplate/update',
      actionAddNewPlantingCategory: 'proposalWizard/addNewPlantingCategory',
      actionUpdatePlantingCategory: 'proposalWizard/updatePlantingCategory',
    }),
    async initForm(data = null) {
      await this.v_toast(this.message?.status, 'Created successfully!');
      await this.$v.form.$reset();
      this.defaultValues();
      this.$emit('onClose', data);
    },
    addNewSize() {
      this.form.price_variables = [
        ...this.form.price_variables,
        {
          id: nanoid(),
          size: '',
          cost: '',
          multiplier: '',
          price: '',
        },
      ];
    },
    deletePriceVariablesItem(id) {
      this.form.price_variables = this.form.price_variables.filter((el) => el.id !== id);
    },
    onChangeCost(item) {
      if (!item.$model.multiplier) item.$model.multiplier = 1;
      // if (item.$model.multiplier === 1) {
      item.$model.price = this.removeDecimals(item.$model.cost * item.$model.multiplier);
      // this.$v.form.price.$model = item.$model.cost * item.$model.multiplier;
      // }
    },
    onChangeMultiplier(item) {
      item.$model.price = this.removeDecimals(item.$model.cost * item.$model.multiplier);
      // this.$v.form.price.$model = this.$v.form.price_variables.$model[0].price;
    },
    onChangePrice(item) {
      let n = this.removeDecimals(item.$model.price / item.$model.cost);

      // if (Math.floor(n) !== n) {
      //   n = n.toFixed(1);
      // }
      item.$model.multiplier = n;
    },
    removeDecimals(n) {
      const parts = n.toString().split('.');
      if (parts.length > 1) {
        n = parts[0] + '.' + parts[1][0];
      }
      return n;
    },
    onChangeCategory() {
      this.possibleSubCategories = [];
      this.form.subcategory = '';
      this.setSubCategories();
    },
    close() {
      this.defaultValues();
      this.$emit('onClose', null);
    },
    async onSubmit(isShouldAdded = false) {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      }

      if (isShouldAdded) {
        this.createAndAddToBuilderInProcess = true;
      } else {
        this.createInProcess = true;
      }

      let data = this.form;
      const newPlantingTemplate = await this.create(data);
      await this.actionAddNewPlantingCategory(newPlantingTemplate.data.data);
      await this.initForm(isShouldAdded ? newPlantingTemplate.data.data : null);

      if (isShouldAdded) {
        this.createAndAddToBuilderInProcess = false;
      } else {
        this.createInProcess = false;
      }
    },
    async onUpdate() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      }

      this.updateInProcess = true;

      let data = {
        id: this.templateId,
        attributes: this.form,
      };
      await this.update(data);
      data.attributes.old_category = this.theItem.attributes.category;
      data.attributes.old_subcategory = this.theItem.attributes.subcategory;
      await this.actionUpdatePlantingCategory(data);
      await this.initForm();

      this.updateInProcess = false;
    },
  },
};
</script>
