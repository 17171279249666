<template>
  <div v-if="plantingSections">
    <form class="form flex-column dense-content pricing">
      <div class="row">
        <div
          :class="[
            'position-relative mb-5 mb-lg-0',
            { 'col-lg-3': isSidebarVisible },
            { 'col-lg-auto': !isSidebarVisible },
          ]"
        >
          <div v-if="isSidebarVisible" class="card position-sticky shadow-sm" :style="{ top: '20px' }">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="mb-0 px-4 h-50px d-flex align-items-center kt_items_list_title">Product library</h4>
              <div class="h-50px w-50px d-flex flex-center">
                <px-btn
                  color="active-light"
                  extended-classes="btn-icon btn-icon-muted btn-active-color-primary w-md-40px h-md-40px"
                  @click.native="toggleSidebar"
                >
                  <span class="svg-icon svg-icon-2hx">
                    <inline-svg src="/assets/media/icons/duotune/abstract/abs015.svg" />
                  </span>
                </px-btn>
              </div>
            </div>
            <div class="ghost-change-order-impossible card-body d-flex flex-column pt-0 px-4">
              <div class="d-flex align-items-center my-1">
                <div class="position-relative flex-grow-1">
                  <span class="svg-icon svg-icon-2 position-absolute top-50 start-0 translate-middle-y ms-2">
                    <inline-svg src="/assets/media/icons/duotune/general/gen021.svg" />
                  </span>
                  <input
                    v-model="searchTextComputed"
                    type="text"
                    class="w-100 form-control form-control-solid ps-10"
                    placeholder="Search Items"
                  />
                  <span
                    v-if="searchTextComputed"
                    class="svg-icon svg-icon-2 position-absolute top-50 end-0 translate-middle-y me-2"
                    role="button"
                    @click="searchTextComputed = ''"
                  >
                    <inline-svg src="/assets/media/icons/duotune/arrows/arr088.svg" />
                  </span>
                </div>
                <px-btn color="light-primary" extended-classes="btn-icon px-4 ms-2" @click.native="openModal">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="/assets/media/icons/duotune/arrows/arr075.svg" />
                  </span>
                </px-btn>
              </div>

              <px-draggable-categories
                v-if="plantingTemplateCategories.size"
                :categories="plantingTemplateCategories"
                :filter-text="searchTextComputed"
                :clone-callback="addNewPlantingSectionItem"
                :action-adding-to-last="addNewPlantingSectionItemToLastPlantingSection"
                :action-edit-template="editPlantingTemplate"
                :action-delete-template="deletePlantingTemplate"
              />
            </div>
          </div>
          <px-btn
            v-else
            color="light-primary"
            extended-classes="btn-icon position-sticky w-md-40px h-md-40px"
            :style="{ top: '20px' }"
            @click.native="toggleSidebar"
          >
            <span class="svg-icon svg-icon-2hx">
              <inline-svg src="/assets/media/icons/duotune/abstract/abs015.svg" />
            </span>
          </px-btn>
        </div>
        <div :class="[{ 'col-lg-9': isSidebarVisible }, { 'col-lg': !isSidebarVisible }]">
          <div class="card shadow-sm h-100">
            <div
              class="card-header position-sticky top-0 z-index-2 align-items-center justify-content-end bg-light border-0 px-4"
            >
              <div v-if="currentProposal && currentProposal.attributes" class="me-4">
                <px-dropdown-content
                  btn-classes="text-muted cursor-pointer p-2"
                  icon-classes="text-inherit bi bi-table"
                >
                  <div slot="content" class="text-start">
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Category</span>
                        <input
                          v-model="currentProposal.attributes.show_planting_category"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_planting_category')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Item</span>
                        <input
                          v-model="currentProposal.attributes.show_planting_item"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_planting_item')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Description</span>
                        <input
                          v-model="currentProposal.attributes.show_planting_description"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_planting_description')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Size</span>
                        <input
                          v-model="currentProposal.attributes.show_planting_size"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_planting_size')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Multiplier</span>
                        <input
                          v-model="currentProposal.attributes.show_planting_multiplier"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_planting_multiplier')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Quantity</span>
                        <input
                          v-model="currentProposal.attributes.show_planting_quantity"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_planting_quantity')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Price ($)</span>
                        <input
                          v-model="currentProposal.attributes.show_planting_price"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_planting_price')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Total</span>
                        <input
                          v-model="currentProposal.attributes.show_planting_total"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_planting_total')"
                        />
                      </div>
                    </div>
                  </div>
                </px-dropdown-content>
              </div>
              <div class="d-flex align-items-center me-n2">
                <span class="fs-6 me-2">Planting Subtotal:</span>
                <strong v-if="plantingSections.length" class="fs-6 fw-bold ms-auto">
                  {{ $n(subTotalFromItems(plantingSections), 'currency', 'en-CA') }}
                </strong>
              </div>
            </div>
            <div class="card-body px-6 d-flex flex-column pt-5">
              <div v-if="!isLoaded" class="text-center text-primary bg-light-primary rounded p-4">
                <div class="fw-bold mb-1">No items yet... :(</div>
                <div>Create some section...</div>
              </div>
              <div v-else class="ghost-change-order-possible-block">
                <draggable handle=".cursor-grab" :list="plantingSections" @change="sortPlantingSectionsByQueue">
                  <px-collapsable-section
                    v-for="section in plantingSections"
                    :key="section.id"
                    :is-opened="section.attributes.is_collapsed"
                    :is-opened-by-dragenter="true"
                    :title="section.attributes.name"
                    :on-toggle="() => onSectionToggle(section.id, section.attributes.is_collapsed)"
                    @on-dragenter="() => onSectionDragenter(section.id, section.attributes.is_collapsed)"
                  >
                    <span slot="header-left" class="cursor-grab d-inline-flex" role="button">
                      <i class="bi bi-grip-vertical text-gray-400 fs-3" />
                    </span>
                    <div slot="header-right" class="d-flex align-items-center me-n2">
                      <strong class="ms-auto fw-bold">
                        {{ $n(totalFromItems(section.attributes.planting_items.data), 'currency', 'en-CA') }}
                      </strong>
                      <!--begin::Menu-->
                      <div class="ms-4">
                        <px-dropdown-content
                          btn-classes="text-muted cursor-pointer p-2"
                          icon-classes="text-inherit bi bi-table"
                        >
                          <div slot="content" class="text-start">
                            <div class="menu-item px-3 my-1">
                              <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                                <span class="text-gray-600">Internal notes</span>
                                <input
                                  v-model="section.attributes.show_notes"
                                  class="ms-auto form-check-input h-20px w-30px"
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          </div>
                        </px-dropdown-content>
                      </div>
                      <!--end::Menu-->
                      <!--begin::Menu-->
                      <div class="ms-4">
                        <px-dropdown
                          :items="
                            section.attributes.show_notes
                              ? [
                                  { title: 'Duplicate', action: () => duplicatePlantingSection(section.attributes) },
                                  { title: 'Add Important Notice', action: () => showPlantingSectionsNote(section.id) },
                                  { title: 'Delete', action: () => deletePlantingSection(section.id) },
                                ]
                              : [
                                  { title: 'Duplicate', action: () => duplicatePlantingSection(section.attributes) },
                                  { title: 'Delete', action: () => deletePlantingSection(section.id) },
                                ]
                          "
                        />
                      </div>
                      <!--end::Menu-->
                    </div>
                    <input
                      slot="header-control"
                      v-model="section.attributes.name"
                      class="w-350px form-control form-control-solid fs-6"
                    />
                    <div class="card-body py-0 px-6">
                      <!--begin::Body-->
                      <div class="py-4">
                        <div class="table-responsive">
                          <table class="ghost-change-order-possible table g-2 mb-0">
                            <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                              <tr>
                                <th class="position-sticky top-0 start-0 z-index-1 min-w-40px px-0" />
                                <th
                                  v-if="currentProposal.attributes.show_planting_category"
                                  :class="[
                                    'border-bottom min-w-100px ps-0',
                                    { 'w-100px': currentProposal.attributes.show_planting_description },
                                  ]"
                                >
                                  Category
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_planting_item"
                                  :class="[
                                    'border-bottom min-w-150px',
                                    { 'w-150px': currentProposal.attributes.show_planting_description },
                                  ]"
                                >
                                  Item
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_planting_description"
                                  class="border-bottom min-w-350px"
                                >
                                  Description
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_planting_size"
                                  :class="[
                                    'border-bottom min-w-100px',
                                    { 'w-100px': currentProposal.attributes.show_planting_description },
                                  ]"
                                >
                                  Size
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_planting_price"
                                  :class="[
                                    'border-bottom min-w-100px',
                                    { 'w-100px': currentProposal.attributes.show_planting_description },
                                  ]"
                                >
                                  Cost($)
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_planting_multiplier"
                                  :class="[
                                    'border-bottom min-w-70px',
                                    { 'w-70px': currentProposal.attributes.show_planting_multiplier },
                                  ]"
                                >
                                  X
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_planting_quantity"
                                  :class="[
                                    'border-bottom min-w-80px',
                                    { 'w-80px': currentProposal.attributes.show_planting_description },
                                  ]"
                                >
                                  QTY
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_planting_price"
                                  :class="[
                                    'border-bottom min-w-100px',
                                    { 'w-100px': currentProposal.attributes.show_planting_description },
                                  ]"
                                >
                                  Price($)
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_planting_total"
                                  :class="[
                                    'border-bottom min-w-100px',
                                    { 'w-100px': currentProposal.attributes.show_planting_description },
                                  ]"
                                >
                                  Total
                                </th>
                                <th class="position-sticky top-0 end-0 z-index-1 w-40px border-bottom" />
                              </tr>
                            </thead>
                            <draggable
                              tag="tbody"
                              group="sub-category-items"
                              :list="section.attributes.planting_items.data"
                              handle=".menu-bullet"
                              @change="sortPlantingSectionItemsByQueue"
                            >
                              <tr
                                v-for="item in section.attributes.planting_items.data"
                                :key="item.id"
                                class="kt-items-list-item additional-control-show-on-hover"
                              >
                                <!--Helpers-->
                                <div class="ghost-content px-4 ms-n8">
                                  <span class="ghost-from-bullet">
                                    <i class="bi bi-grip-vertical fs-3" />
                                  </span>
                                  <span class="ghost-title">
                                    {{ item.attributes.planting_item_template.data.attributes.english_name }}
                                  </span>
                                </div>
                                <td
                                  class="ghost-helper ghost-helper-d-table-cell ghost-helper-space-start"
                                  colspan="8"
                                />
                                <!--/Helpers-->
                                <td class="ghost-invisible position-sticky top-0 start-0 z-index-1 bg-white p-0">
                                  <div
                                    class="additional-control d-inline-flex align-items-center justify-content-center w-100 text-center mt-4"
                                  >
                                    <span class="handle menu-bullet d-inline-flex cursor-grab" role="button">
                                      <i class="bi bi-grip-vertical fs-3" />
                                    </span>
                                  </div>
                                </td>
                                <td v-if="currentProposal.attributes.show_planting_category" class="ghost-hidden ps-0">
                                  <textarea-autosize
                                    v-if="item.attributes.planting_item_template.data"
                                    v-model="item.attributes.planting_item_template.data.attributes.category"
                                    class="form-control form-control-solid fw-normal"
                                    rows="1"
                                    :min-height="42"
                                    :max-height="500"
                                    readonly
                                  />
                                  <input v-else value="" class="form-control form-control-solid fw-normal" readonly />
                                </td>
                                <td v-if="currentProposal.attributes.show_planting_item" class="ghost-hidden">
                                  <input
                                    v-if="item.attributes.planting_item_template.data"
                                    v-model="item.attributes.planting_item_template.data.attributes.english_name"
                                    class="form-control form-control-solid fw-normal"
                                    readonly
                                  />
                                  <input v-else value="" class="form-control form-control-solid fw-normal" readonly />
                                  <em class="d-block pt-2">
                                    {{ item.attributes.planting_item_template.data.attributes.botanical_name }}
                                  </em>
                                </td>
                                <td v-if="currentProposal.attributes.show_planting_description" class="ghost-hidden">
                                  <textarea-autosize
                                    v-model="item.attributes.description"
                                    class="form-control form-control-solid fw-normal"
                                    rows="1"
                                    :min-height="42"
                                    :max-height="500"
                                  />
                                  <div class="row align-items-center small pt-2 gx-4">
                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.full_sun"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon">
                                        <inline-svg src="/assets/media/icons/custom/full-sun.svg" />
                                      </span>
                                    </div>
                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.partial_sun"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon">
                                        <inline-svg src="/assets/media/icons/custom/partial-sun.svg" />
                                      </span>
                                    </div>
                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.full_shade"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon">
                                        <inline-svg src="/assets/media/icons/custom/full-shade.svg" />
                                      </span>
                                    </div>
                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.native"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon">
                                        <inline-svg src="/assets/media/icons/custom/native.svg" stroke="#a1a5b7" />
                                      </span>
                                    </div>
                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.attracts_birds"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon">
                                        <inline-svg
                                          src="/assets/media/icons/custom/attractive-birds.svg"
                                          stroke="#a1a5b7"
                                        />
                                      </span>
                                    </div>

                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.height"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon me-1">
                                        <inline-svg src="/assets/media/icons/custom/height.svg" />
                                      </span>
                                      {{ item.attributes.planting_item_template.data.attributes.height }}
                                    </div>
                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.bloom"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon me-1">
                                        <inline-svg src="/assets/media/icons/custom/bloom-color.svg" />
                                      </span>
                                      {{ item.attributes.planting_item_template.data.attributes.bloom }}
                                    </div>
                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.spread"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon me-1">
                                        <inline-svg src="/assets/media/icons/custom/spread.svg" />
                                      </span>
                                      {{ item.attributes.planting_item_template.data.attributes.spread }}
                                    </div>
                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.foliage"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon me-1">
                                        <inline-svg src="/assets/media/icons/custom/foliage-value.svg" />
                                      </span>
                                      {{ item.attributes.planting_item_template.data.attributes.foliage }}
                                    </div>
                                    <div
                                      v-if="item.attributes.planting_item_template.data.attributes.shape"
                                      class="col-auto text-nowrap mb-2"
                                    >
                                      <span class="svg-icon me-1">
                                        <inline-svg src="/assets/media/icons/custom/shape.svg" />
                                      </span>
                                      {{ item.attributes.planting_item_template.data.attributes.shape }}
                                    </div>
                                  </div>
                                  <div v-if="item.attributes.show_client_note" class="position-relative mt-2">
                                    <span
                                      class="p-3 text-hover-danger position-absolute top-0 end-0 mt-n1 me-n9"
                                      role="button"
                                      @click="() => hidePlantingSectionItemsNote(section.id, item.id)"
                                    >
                                      <i class="bi bi-x" />
                                    </span>
                                    <label class="d-block position-relative">
                                      <textarea-autosize
                                        v-model="item.attributes.note"
                                        class="form-control form-control-solid fw-normal border-0 pt-8 bg-light-warning"
                                        rows="1"
                                        :min-height="60"
                                        :max-height="500"
                                      />
                                      <span class="label-inside position-absolute top-0 start-0 pb-1 mb-n2">
                                        Client Note
                                      </span>
                                    </label>
                                  </div>
                                  <div
                                    v-if="item.attributes.show_internal_note && section.attributes.show_notes"
                                    :class="[
                                      'position-relative',
                                      { 'mt-4': item.attributes.show_internal_note },
                                      { 'mt-2': !item.attributes.show_internal_note },
                                    ]"
                                  >
                                    <span
                                      class="p-3 text-hover-danger position-absolute top-0 end-0 mt-n1 me-n9"
                                      role="button"
                                      @click="() => hidePlantingSectionItemsInternalNote(section.id, item.id)"
                                    >
                                      <i class="bi bi-x" />
                                    </span>
                                    <label class="d-block position-relative">
                                      <textarea-autosize
                                        v-model="item.attributes.internal_note"
                                        class="form-control form-control-solid fw-normal border-0 pt-8 bg-light-danger border-0"
                                        rows="1"
                                        :min-height="60"
                                        :max-height="500"
                                      />
                                      <span class="label-inside position-absolute top-0 start-0 pb-1 mb-n2">
                                        Internal Note
                                      </span>
                                    </label>
                                  </div>
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_planting_size"
                                  class="ghost-hidden text-nowrap"
                                >
                                  <px-select
                                    :value="
                                      item.attributes.size ||
                                      ((item.attributes.planting_item_template.data.attributes.price_variables || [])
                                        .length === 1 &&
                                      item.attributes.planting_item_template.data.attributes.price_variables[0].id ===
                                        '0'
                                        ? null
                                        : getVal(
                                            item.attributes,
                                            'planting_item_template.data.attributes.price_variables[0].size'
                                          ))
                                    "
                                    @input="(val) => handleSizeChange(item, val)"
                                    :clear="false"
                                    :placeholder="
                                      (item.attributes.planting_item_template.data.attributes.price_variables || [])
                                        .length === 1 &&
                                      item.attributes.planting_item_template.data.attributes.price_variables[0].id ===
                                        '0'
                                        ? 'No Sizes'
                                        : 'Select size'
                                    "
                                    :disabled="
                                      (item.attributes.planting_item_template.data.attributes.price_variables || [])
                                        .length === 1 &&
                                      item.attributes.planting_item_template.data.attributes.price_variables[0].id ===
                                        '0'
                                    "
                                    :items="
                                      (
                                        item.attributes.planting_item_template.data.attributes.price_variables || []
                                      ).filter((el) => el.id !== '0')
                                    "
                                    track-by="size"
                                    text-by="size"
                                    :minimum-results-for-search="Infinity"
                                    :select2-id="`select2_price_variables_${section.id}_${item.id}`"
                                  />
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_planting_price"
                                  class="ghost-hidden text-nowrap"
                                >
                                  <PxMoney
                                    v-model="item.attributes.cost"
                                    class="form-control form-control-solid fw-normal"
                                    :decimal-scale="1"
                                    @input="updatePrice(item)"
                                  />
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_planting_multiplier"
                                  class="ghost-hidden text-nowrap"
                                >
                                  <PxMoney
                                    v-model="item.attributes.multiplier"
                                    class="form-control form-control-solid fw-normal"
                                    :decimal-scale="1"
                                    @input="updatePrice(item)"
                                  />
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_planting_quantity"
                                  class="ghost-hidden text-nowrap"
                                >
                                  <PxMoney
                                    v-model="item.attributes.quantity"
                                    class="form-control form-control-solid fw-normal"
                                    :decimal-scale="1"
                                    @input="updatePrice(item)"
                                  />
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_planting_price"
                                  class="ghost-hidden text-nowrap"
                                >
                                  <div v-if="item.attributes.price_format === 'Default'">
                                    <money
                                      v-model="item.attributes.price"
                                      v-bind="moneyWithPrecision"
                                      type="text"
                                      class="form-control form-control-solid fw-normal"
                                      disabled
                                    />
                                  </div>
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_planting_total"
                                  class="ghost-hidden text-nowrap fw-bold"
                                >
                                  <div
                                    v-if="item.attributes.price_format === 'Default'"
                                    class="d-flex align-items-center"
                                    :style="{ height: '44px' }"
                                  >
                                    {{ $n(totalFromItems([item]), 'currency', 'en-CA') }}
                                  </div>
                                  <select
                                    v-else
                                    v-model="item.attributes.price_format"
                                    class="form-select form-select-solid w-175px"
                                  >
                                    <option
                                      v-for="priceFormat in plantingItemsPriceFormats"
                                      :value="priceFormat"
                                      :key="priceFormat"
                                    >
                                      {{ priceFormat }}
                                    </option>
                                  </select>
                                </td>
                                <td
                                  class="ghost-hidden position-sticky bottom-0 end-0 z-index-1 w-25px bg-white text-end pe-0"
                                >
                                  <div class="d-inline-flex align-items-center" :style="{ height: '44px' }">
                                    <px-dropdown
                                      :items="
                                        section.attributes.show_notes
                                          ? [
                                              {
                                                title: 'Duplicate',
                                                action: () => duplicatePlantingSectionItem(section.id, item.attributes),
                                              },
                                              {
                                                title: 'Change Price Format',
                                                action: () =>
                                                  togglePlantingSectionItemsPriceFormat(section.id, item.id),
                                              },
                                              {
                                                title: 'Add Client Note',
                                                action: () => showPlantingSectionItemsNote(section.id, item.id),
                                              },
                                              {
                                                title: 'Add Internal Note',
                                                action: () => showPlantingSectionItemsInternalNote(section.id, item.id),
                                              },
                                              {
                                                title: 'Delete',
                                                action: () => deletePlantingSectionItem(section.id, item.id),
                                              },
                                            ]
                                          : [
                                              {
                                                title: 'Duplicate',
                                                action: () => duplicatePlantingSectionItem(section.id, item.attributes),
                                              },
                                              {
                                                title: 'Change Price Format',
                                                action: () =>
                                                  togglePlantingSectionItemsPriceFormat(section.id, item.id),
                                              },
                                              {
                                                title: 'Add Client Note',
                                                action: () => showPlantingSectionItemsNote(section.id, item.id),
                                              },
                                              {
                                                title: 'Delete',
                                                action: () => deletePlantingSectionItem(section.id, item.id),
                                              },
                                            ]
                                      "
                                    />
                                  </div>
                                </td>
                              </tr>
                            </draggable>
                            <tfoot>
                              <tr>
                                <td
                                  class="align-middle fw-bold text-uppercase text-ls fs-9 text-muted text-end pt-4"
                                  :colspan="
                                    countTrueQty(
                                      [
                                        currentProposal.attributes.show_planting_category,
                                        currentProposal.attributes.show_planting_item,
                                        currentProposal.attributes.show_planting_multiplier,
                                        currentProposal.attributes.show_planting_description,
                                        currentProposal.attributes.show_planting_size,
                                        currentProposal.attributes.show_planting_quantity,
                                        currentProposal.attributes.show_planting_price,
                                        true,
                                      ],
                                      1
                                    )
                                  "
                                >
                                  Total:
                                </td>
                                <td class="align-middle fw-bold pt-4">
                                  <div class="d-flex align-items-center">
                                    {{
                                      $n(totalFromItems(section.attributes.planting_items.data), 'currency', 'en-CA')
                                    }}
                                  </div>
                                </td>
                                <td class="position-sticky bottom-0 end-0 z-index-1 min-w-40px" />
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>

                      <div v-if="section.attributes.show_important_notice" class="position-relative mb-6 ms-n8 ps-8">
                        <span
                          class="p-3 text-hover-danger position-absolute top-0 end-0 mt-n1 me-n9"
                          role="button"
                          @click="() => hidePlantingSectionsNote(section.id)"
                        >
                          <i class="bi bi-x" />
                        </span>
                        <label class="d-block position-relative">
                          <textarea-autosize
                            v-model="section.attributes.note"
                            class="form-control form-control-solid fw-normal border-0 pt-8 bg-light-warning"
                            rows="1"
                            :min-height="60"
                            :max-height="500"
                          />
                          <span class="label-inside position-absolute top-0 start-0 pb-1 mb-n2">Important Notice</span>
                        </label>
                      </div>
                      <!--end::Body-->
                    </div>
                  </px-collapsable-section>
                </draggable>
              </div>

              <div class="text-end mt-4">
                <px-btn
                  size="sm"
                  color="light-primary"
                  extended-classes="add-item-group"
                  @click.native="addNewPlantingSection"
                >
                  <i class="bi bi-plus-lg" />
                  New Section
                </px-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row">
      <div :class="[{ 'col-lg-3': isSidebarVisible }, { 'col-lg-auto': !isSidebarVisible }]" />
      <div :class="[{ 'col-lg-9': isSidebarVisible }, { 'col-lg': !isSidebarVisible }]">
        <!--begin::Actions-->
        <div class="d-flex flex-stack mt-8 pt-8 border-top">
          <!--begin::Wrapper-->
          <div class="me-4">
            <px-btn color="secondary" :in-process="prevInProcess" :disabled="prevInProcess" @click.native="goToPrev">
              Back
            </px-btn>
          </div>
          <!--end::Wrapper-->
          <!--begin::Wrapper-->
          <div class="ms-auto">
            <px-btn
              color="success"
              extended-classes="me-2"
              :in-process="updateInProcess"
              :disabled="updateInProcess"
              @click.native="onUpdate"
            >
              Save
            </px-btn>
            <px-btn :in-process="nextInProcess" :disabled="nextInProcess" @click.native="goToNext">Next </px-btn>
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Actions-->
      </div>
    </div>

    <!-- new planting item template begin -->
    <px-new-modal :is-visible="isPlantingItemTemplateModalOpened" :close-modal="closeModal">
      <template #title>
        <h3>{{ editingTemplateId ? 'Update Item' : 'New Item' }}</h3>
      </template>
      <template #content>
        <pxm-new-planting-item-template :template-id="editingTemplateId" @onClose="closeModal" />
      </template>
    </px-new-modal>
    <!-- new planting item template end -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ProposalWizardMixin from '@/mixins/ProposalWizardMixin';

import PxCollapsableSection from '@/views/proposals/wizard/components/PxCollapsableSection';
import PxDraggableCategories from '@/views/proposals/wizard/components/PxDraggableCategories';

import PxmNewPlantingItemTemplate from '@/modals/PxmNewPlantingItemTemplate';

import draggable from 'vuedraggable';
import { nanoid } from 'nanoid';
import { debounce, get as getVal, cloneDeep } from 'lodash';

export default {
  mixins: [ProposalWizardMixin],
  components: {
    PxCollapsableSection,
    PxDraggableCategories,
    PxmNewPlantingItemTemplate,
    draggable,
  },
  data() {
    return {
      getVal,
      searchText: '',
      isSubmitDisable: false,
      isSidebarVisible: true,
      editingTemplateId: null,
      isPlantingItemTemplateModalOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      message: 'proposalWizard/message',
      isLoaded: 'proposalWizard/isLoaded',
      // Planting templates
      plantingItemTemplateMessage: 'plantingItemTemplate/message',
      plantingTemplateCategories: 'proposalWizard/plantingTemplateCategories',
      // Planting section
      currentProposal: 'proposalWizard/currentProposal',
      plantingSections: 'proposalWizard/plantingSections',
      plantingSectionActiveIndex: 'proposalWizard/plantingSectionActiveIndex',
    }),
    plantingItemsPriceFormats() {
      return ['To be determined', 'Included'];
    },
    searchTextComputed: {
      get() {
        return this.searchText;
      },
      set: debounce(function (newValue) {
        this.searchText = newValue;
      }, 500),
    },
  },
  async mounted() {
    await this.plantingItemTemplateInitCategories();
    await this.setPlantingSectionActiveIndex(this.plantingSections.length);
    await this.actionSetPlantingSectionIsOpen({
      id: this.plantingSections.find((el, i) => i + 1 === this.plantingSections.length).id,
      is_collapsed: true,
    });
  },
  methods: {
    ...mapActions({
      // Planting templates
      plantingItemTemplateInitCategories: 'plantingItemTemplate/initCategories',
      plantingItemTemplateArchive: 'plantingItemTemplate/archive',
      actionDeletePlantingCategory: 'proposalWizard/deletePlantingCategory',
      // Planting section
      megaUpdate: 'proposalWizard/megaUpdate',
      actionRevertValue: 'proposalWizard/revertValue',
      actionAddNewPlantingSection: 'proposalWizard/addNewPlantingSection',
      actionDeletePlantingSection: 'proposalWizard/deletePlantingSection',
      actionSetPlantingSectionsNoteVisibility: 'proposalWizard/setPlantingSectionsNoteVisibility',
      actionSetPlantingSectionIsOpen: 'proposalWizard/setPlantingSectionIsOpen',
      actionSortPlantingSectionsByQueue: 'proposalWizard/sortPlantingSectionsByQueue',
      actionAddNewPlantingSectionItem: 'proposalWizard/addNewPlantingSectionItem',
      actionDeletePlantingSectionItem: 'proposalWizard/deletePlantingSectionItem',
      actionTogglePlantingSectionItemsPriceFormat: 'proposalWizard/togglePlantingSectionItemsPriceFormat',
      actionSetPlantingSectionItemsNoteVisibility: 'proposalWizard/setPlantingSectionItemsNoteVisibility',
      actionDeletePlantingSectionItemsNote: 'proposalWizard/deletePlantingSectionItemsNote',
      actionSetPlantingSectionItemsInternalNoteVisibility:
        'proposalWizard/setPlantingSectionItemsInternalNoteVisibility',
      actionDeletePlantingSectionItemsInternalNote: 'proposalWizard/deletePlantingSectionItemsInternalNote',
      actionSortPlantingSectionItemsByQueue: 'proposalWizard/sortPlantingSectionItemsByQueue',
    }),
    ...mapMutations({
      setPlantingSections: 'proposalWizard/SET_PLANTING_SECTIONS',
      setPlantingSectionActiveIndex: 'proposalWizard/SET_PLANTING_SECTION_ACTIVE_INDEX',
    }),
    updatePrice(item) {
      item.attributes.price = item.attributes.cost * item.attributes.multiplier;
    },
    handleSizeChange(item, val) {
      const size = item.attributes.planting_item_template.data.attributes.price_variables.find((s) => s.size == val);
      item.attributes.size = val;
      item.attributes.cost = size.cost;
      item.attributes.multiplier = size.multiplier;
      this.updatePrice(item);
    },
    // Helpers
    subTotalFromItems(items) {
      let totalPrice = 0;

      if (items.length) {
        items.map((el) => {
          const subTotal = this.totalFromItems(el.attributes.planting_items.data);
          totalPrice += subTotal;
        });
      }

      return totalPrice;
    },
    totalFromItems(items) {
      let totalPrice = 0;

      items.map((el) => {
        if (el.attributes.price_format === 'Default') {
          const { cost, multiplier, quantity } = el.attributes;
          totalPrice += cost * multiplier * quantity;
        }
      });

      return totalPrice;
    },
    clearString(str) {
      return str.split('(')[0].toLowerCase();
    },
    onCheckboxChange(field) {
      this.actionRevertValue(field);
    },
    // Planting sections
    onSectionToggle(id, isOpened) {
      this.actionSetPlantingSectionIsOpen({
        id,
        is_collapsed: !isOpened,
      });
      this.setPlantingSectionActiveIndexAsLastOpenedSectionIndex();
    },
    onSectionDragenter(id, isOpened) {
      if (!isOpened) {
        this.actionSetPlantingSectionIsOpen({
          id,
          is_collapsed: true,
        });
        this.setPlantingSectionActiveIndexAsLastOpenedSectionIndex();
      }
    },
    setPlantingSectionActiveIndexAsLastOpenedSectionIndex() {
      let lastOpenedIndex;

      this.plantingSections.forEach((el, i) => {
        if (el.attributes.is_collapsed) {
          lastOpenedIndex = i + 1;
        }
      });

      if (this.plantingSectionActiveIndex !== lastOpenedIndex && lastOpenedIndex !== undefined) {
        this.setPlantingSectionActiveIndex(lastOpenedIndex);
      } else {
        this.setPlantingSectionActiveIndex(this.plantingSections.length);
      }
    },
    addNewPlantingSection() {
      const newSectionId = nanoid();

      this.actionAddNewPlantingSection({
        id: newSectionId,
        type: 'planting_section',
        attributes: {
          header_settings: ['Item', 'Description', 'Quantity', 'Price($)', 'Unit', 'Waste(%)', 'Total'],
          name: null,
          note: null,
          internal_note: null,
          planting_items: {
            data: [],
          },
          proposal_scope: {
            data: this.currentProposal,
          },
          queue: this.plantingSections.length,
          is_collapsed: false,
          show_category: true,
          show_item: true,
          show_notes: true,
          show_description: true,
          show_size: true,
          show_quantity: true,
          show_price: true,
          show_total: true,
          show_multiplier: true,
          show_important_notice: false,
        },
      });
      this.setPlantingSections(this.plantingSections);
      this.onSectionToggle(newSectionId, false);
    },
    duplicatePlantingSection(attributes) {
      const newAttributes = cloneDeep(attributes);

      if (!newAttributes.planting_items?.data?.length) {
        this.v_toast(301, `Planting section is empty. Please add some items for duplication.`, () => {}, {
          timer: 5000,
        });

        return false;
      }

      this.actionAddNewPlantingSection({
        id: nanoid(),
        type: 'planting_section',
        attributes: newAttributes,
      });
      this.setPlantingSections(this.plantingSections);
      this.setPlantingSectionActiveIndex(this.plantingSections.length);
      this.setPlantingSectionActiveIndexAsLastOpenedSectionIndex();
    },
    deletePlantingSection(id) {
      this.actionDeletePlantingSection(id);
      this.setPlantingSections(this.plantingSections);
      this.setPlantingSectionActiveIndex(this.plantingSections.length);
      this.setPlantingSectionActiveIndexAsLastOpenedSectionIndex();
    },
    showPlantingSectionsNote(id) {
      this.actionSetPlantingSectionsNoteVisibility({ id: id, state: true });
      this.setPlantingSections(this.plantingSections);
    },
    hidePlantingSectionsNote(id) {
      this.actionSetPlantingSectionsNoteVisibility({ id: id, state: false });
      this.setPlantingSections(this.plantingSections);
    },
    sortPlantingSectionsByQueue() {
      this.actionSortPlantingSectionsByQueue();
      this.setPlantingSections(this.plantingSections);
    },
    // Planting section items
    addNewPlantingSectionItem(item) {
      const newAttributes = cloneDeep(item.attributes);

      const size = newAttributes.price_variables[0]?.size;
      const cost = newAttributes.price_variables[0]?.cost;
      const price = newAttributes.price_variables[0]?.price;
      const multiplier = newAttributes.price_variables[0]?.multiplier;
      return {
        id: nanoid(),
        type: 'planting_item',
        attributes: {
          category: newAttributes.category,
          item: newAttributes.english_name,
          description: newAttributes.description,
          cost: cost,
          size: size,
          price: price,
          quantity: 1,
          price_format: 'Default',
          multiplier: multiplier,
          note: null,
          internal_note: newAttributes.internal_note,
          planting_item_template: {
            data: { ...item },
          },
        },
        show_client_note: false,
        show_internal_note: true,
      };
    },
    addNewPlantingSectionItemToLastPlantingSection(item) {
      const newAttributes = cloneDeep(item.attributes);
      if (!this.plantingSections.filter((el) => el.attributes.is_collapsed).length) {
        this.actionSetPlantingSectionIsOpen({
          id: this.plantingSections[this.plantingSectionActiveIndex - 1].id,
          is_collapsed: true,
        });
      }

      const size = newAttributes.price_variables[0]?.size;
      const cost = newAttributes.price_variables[0]?.cost;
      const price = newAttributes.price_variables[0]?.price;
      const multiplier = newAttributes.price_variables[0]?.multiplier;

      this.duplicatePlantingSectionItem(this.plantingSections[this.plantingSectionActiveIndex - 1].id, {
        queue: this.plantingSections[this.plantingSectionActiveIndex - 1].attributes.planting_items.data.length,
        category: newAttributes.category,
        item: newAttributes.english_name,
        description: newAttributes.description,
        cost: cost,
        size: size,
        quantity: 1,
        price: price,
        multiplier: multiplier,
        price_format: 'Default',
        note: newAttributes.note,
        internal_note: newAttributes.internal_note,
        planting_item_template: {
          data: { ...item },
        },
        show_client_note: newAttributes.show_client_note,
        show_internal_note: newAttributes.show_internal_note,
      });
      this.setPlantingSections(this.plantingSections);
    },
    async editPlantingTemplate(data) {
      this.editingTemplateId = data.id;

      this.isPlantingItemTemplateModalOpened = true;
    },
    async deletePlantingTemplate(item) {
      await this.plantingItemTemplateArchive(item.id);
      await this.actionDeletePlantingCategory(item);
      await this.v_toast(this.plantingItemTemplateMessage?.status, 'Deleted successfully!');
    },
    duplicatePlantingSectionItem(sectionId, attributes) {
      const newAttributes = cloneDeep(attributes);

      this.actionAddNewPlantingSectionItem({
        sectionId: sectionId,
        item: {
          id: nanoid(),
          type: 'planting_item',
          attributes: newAttributes,
        },
      });
      this.setPlantingSections(this.plantingSections);
    },
    deletePlantingSectionItem(sectionId, id) {
      this.actionDeletePlantingSectionItem({ sectionId: sectionId, id: id });
      this.setPlantingSections(this.plantingSections);
    },
    togglePlantingSectionItemsPriceFormat(sectionId, id) {
      this.actionTogglePlantingSectionItemsPriceFormat({ sectionId: sectionId, id: id });
      this.setPlantingSections(this.plantingSections);
    },
    showPlantingSectionItemsNote(sectionId, id) {
      this.actionSetPlantingSectionItemsNoteVisibility({ sectionId: sectionId, id: id, state: true });
      this.setPlantingSections(this.plantingSections);
    },
    hidePlantingSectionItemsNote(sectionId, id) {
      this.actionSetPlantingSectionItemsNoteVisibility({ sectionId: sectionId, id: id, state: false });
      this.actionDeletePlantingSectionItemsNote({ sectionId: sectionId, id: id, state: '' });
      this.setPlantingSections(this.plantingSections);
    },
    showPlantingSectionItemsInternalNote(sectionId, id) {
      this.actionSetPlantingSectionItemsInternalNoteVisibility({ sectionId: sectionId, id: id, state: true });
      this.setPlantingSections(this.plantingSections);
    },
    hidePlantingSectionItemsInternalNote(sectionId, id) {
      this.actionSetPlantingSectionItemsInternalNoteVisibility({ sectionId: sectionId, id: id, state: false });
      this.actionDeletePlantingSectionItemsInternalNote({ sectionId: sectionId, id: id, state: '' });
      this.setPlantingSections(this.plantingSections);
    },
    sortPlantingSectionItemsByQueue() {
      this.actionSortPlantingSectionItemsByQueue();
      this.setPlantingSections(this.plantingSections);
    },
    // General
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    openModal() {
      this.isPlantingItemTemplateModalOpened = true;
    },
    closeModal(data) {
      this.editingTemplateId = null;
      this.isPlantingItemTemplateModalOpened = false;

      if (data) {
        this.addNewPlantingSectionItemToLastPlantingSection(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.kt-items-list-item {
  .add-item-btn {
    opacity: 0;
  }

  &:hover {
    .add-item-btn {
      opacity: 1;
    }
  }
}

.parent:hover {
  .opacity-100-p-hover {
    opacity: 1 !important;
  }
}
</style>
