import { mapActions, mapGetters } from 'vuex';

import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';

import { required } from 'vuelidate/lib/validators';

const initialPriceVariablesItems = [
  {
    id: nanoid(),
    size: '',
    cost: '',
    multiplier: '',
    price: '',
  },
];

export default {
  props: {
    templateId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      theItem: null,
      money: {
        decimal: '.',
        thousands: ',',
        precision: 0,
        masked: false,
      },
      form: {
        english_name: '',
        botanical_name: '',
        price: '',
        item_size: '',
        full_shade: false,
        partial_sun: false,
        full_sun: false,
        native: false,
        attracts_birds: false,
        foliage: '',
        bloom: '',
        zone: '',
        description: '',
        height: '',
        spread: '',
        shape: '',
        category: '',
        subcategory: '',
        note: '',
        internal_note: '',
        price_variables: cloneDeep(initialPriceVariablesItems),
      },
      possibleCategories: [],
      possibleSubCategories: [],
      showModal: false,
    };
  },
  validations: {
    form: {
      english_name: { required },
      botanical_name: { required },
      price: {},
      item_size: { required },
      description: { required },
      category: { required },
      subcategory: { required },
      price_variables: {
        $each: {
          id: { required },
          size: { required },
          cost: { required },
          multiplier: { required },
          price: { required },
          $trackBy: 'id',
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      categories: 'plantingItemTemplate/categories',
    }),
    possibleSizeValues() {
      return [
        {
          id: '1001',
          title: 'cm',
        },
        {
          id: '1002',
          title: 'm',
        },
      ];
    },
    possibleShapes() {
      return [
        {
          id: '1001',
          title: 'Pyramidal',
        },
        {
          id: '1002',
          title: 'Woodwork',
        },
        {
          id: '1003',
          title: 'Landscape',
        },
      ];
    },
  },
  async mounted() {
    if (this.templateId) {
      this.theItem = await this.actionPlantingItemTemplateSingle(this.templateId);
      this.theItem = this.theItem.data.data;
      const itemData = await this.theItem.attributes;
      const price_variables = Array.isArray(itemData.price_variables) ? itemData.price_variables : [];
      this.form = {
        english_name: itemData.english_name || '',
        botanical_name: itemData.botanical_name || '',
        price: itemData.price || '',
        item_size: price_variables.length ? price_variables[0].size : itemData.item_size || '',
        full_shade: itemData.full_shade || false,
        partial_sun: itemData.partial_sun || false,
        full_sun: itemData.full_sun || false,
        native: itemData.native || false,
        attracts_birds: itemData.attracts_birds || false,
        foliage: itemData.foliage || '',
        bloom: itemData.bloom || '',
        zone: itemData.zone || '',
        description: itemData.description || '',
        height: itemData.height || '',
        spread: itemData.spread || '',
        shape: itemData.shape || '',
        category: itemData.category || '',
        subcategory: itemData.subcategory || '',
        note: itemData.note || '',
        internal_note: itemData.internal_note || '',
        price_variables: price_variables || [...initialPriceVariablesItems],
      };
    }

    await this.setCategories();

    if (this.form.subcategory) {
      this.setSubCategories();
    }
  },
  methods: {
    ...mapActions({
      actionPlantingItemTemplateSingle: 'plantingItemTemplate/single',
    }),
    setCategories() {
      const newPossibleCategories = [];
      let counter = 1;

      for (let category of this.categories.keys()) {
        newPossibleCategories.push({
          id: `100${counter}`,
          title: category,
        });
        counter++;
      }

      this.possibleCategories = newPossibleCategories;
    },
    setSubCategories() {
      const newPossibleSubCategories = [];
      let counter = 1;
      const currentCategory = this.categories.get(this.form.category);

      for (let subCategory of currentCategory.keys()) {
        newPossibleSubCategories.push({
          id: `100${counter}`,
          title: subCategory,
        });
        counter++;
      }

      this.possibleSubCategories = newPossibleSubCategories;
    },
    defaultValues() {
      this.form = {
        english_name: '',
        botanical_name: '',
        price: '',
        item_size: '',
        full_shade: false,
        partial_sun: false,
        full_sun: false,
        native: false,
        attracts_birds: false,
        foliage: '',
        bloom: '',
        zone: '',
        description: '',
        height: '',
        spread: '',
        shape: '',
        category: '',
        subcategory: '',
        note: '',
        internal_note: '',
        price_variables: cloneDeep(initialPriceVariablesItems),
      };
    },
  },
};
